import React, {useEffect} from 'react'
import './Address.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAddresses } from '../Addresses/addressesSlice'
import Ad from '../../Components/Ad/Ad'
import { Address as AddressBox } from '../Addresses/Addresses'

const Address = () => {

	const dispatch = useDispatch()
	const {id} = useParams()
	const {fetched, content} = useSelector(state => state.addresses);

	useEffect(() => {
		if(!fetched) {
			dispatch(getAddresses())
		}
	}, [dispatch, fetched])

	const {name, title, text, image, ad, ad_active } = content.layout ? content.layout.find(item => item.slug === id) : {};
	const rest = content.layout ? content.layout.filter(item => (item.slug !== id && item.acf_fc_layout === 'address')) : [];

	return(
		<div className="AddressSingle">
			<div className="content">
				{ name && <p className="heading" dangerouslySetInnerHTML={{__html: name}} /> }
				{ image && <img src={image.sizes.large} alt={name ? name : ''} /> }
				{ title && <p className="title" dangerouslySetInnerHTML={{__html: title}} /> }
				{ text && <div className="text" dangerouslySetInnerHTML={{__html: text}} /> }
			</div>
			{ ad_active && <Ad image={ad.image} url={ad.link ? ad.link : ''} /> }

			{
				rest && rest.map((address, i) => <AddressBox key={`AdressesRest_${i}`} {...address} />)
			}

		</div>
	)
}

export default Address
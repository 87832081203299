import React from 'react'
import './Ad.scss'

export default function Ad({image, url}) {
	return(
		<div className="Ad">
			<p>Annonse</p>
			<a href={url} target="_blank" rel="noreferrer">
				<img src={image.sizes.large} alt="Annonse" />
			</a>
		</div>
	)
}
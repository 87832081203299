import React from 'react'
import { useDispatch } from 'react-redux'
import { getJury, toggleCategory } from './jurySlice'
import { AnimatePresence, motion } from 'framer-motion'
import Loader from '../../Components/Loader/Loader'
import {ReactComponent as Arrow} from '../../media/Arrow.svg'

const CategoryJury = ({items}) => (
	<motion.div
		initial={{height: 0}}
		animate={{height: 'auto'}}
		exit={{height: 0}}
		className="content"
	>
		{
			items.map(item => (
				<p key={item} dangerouslySetInnerHTML={{__html: item}} />
			))
		}
	</motion.div>
)

const Category = ({id, name, layoutRow, row, items, loading, expanded, fetched}) => {

	const dispatch = useDispatch()

	const toggle = () => {
		if(!fetched) {
			dispatch(getJury({id, layoutRow, row}))
		}
		dispatch(toggleCategory({id, layoutRow, row}));
	}

	return(
		<div className={expanded ? 'JuryListCategory active' : 'JuryListCategory'}>
			<button onClick={toggle}>
				<span>{name}</span>
				{ loading && <Loader /> }
				<Arrow />
			</button>
			<AnimatePresence initial={false}>
				{
					!loading && expanded &&
					<CategoryJury items={items} />
				}
			</AnimatePresence>
		</div>
	)
}

const JuryList = ({row, categories}) => {
	return(
		<div className="JuryList">
			{ categories.map((item, i) => <Category key={`JuryList_${row}_${i}`} layoutRow={row} row={i} {...item} />) }
		</div>
	)
}

export default JuryList
import React, {useEffect} from 'react'
import Loader from '../../Components/Loader/Loader'
import Layout from '../../Components/Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from './jurySlice'
import './Jury.scss'

const Jury = () => {

	const dispatch = useDispatch();
	const {fetched, content, loading} = useSelector(state => state.jury)
	const {title, layout} = content;

	useEffect(() => {
		if(!fetched) { dispatch(getCategories()) }
	}, [dispatch, fetched])

	return(
		<div className="Jury">
			{
				loading
				? <Loader />
				:
				<>
					{ title && <h1 className="heading" dangerouslySetInnerHTML={{__html: title}} /> }
					{ layout && <Layout layout={layout} section="jury" /> }
				</>
			}
		</div>
	)
}

export default Jury
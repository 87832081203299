import React from 'react'
import { NavLink } from 'react-router-dom'
import './Navigation.scss'

const Navigation = () => {
	return(
		<nav className="Navigation">
			<ul>
				<li>
					<NavLink to="/">Hjem</NavLink>
				</li>
				<li>
					<NavLink to="/nominerte">Nominerte</NavLink>
				</li>
				<li>
					<NavLink to="/jury">Jury</NavLink>
				</li>
				<li>
					<NavLink end to="/hilsener">Hilsener</NavLink>
				</li>
				<li>
					<NavLink to="/komiteen">Komitéen</NavLink>
				</li>
			</ul>
		</nav>
	)
}

export default Navigation
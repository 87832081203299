import React from 'react'
import { useDispatch } from 'react-redux'
import { getNominees, toggleCategory } from './nomineesSlice'
import { AnimatePresence, motion } from 'framer-motion'
import Loader from '../../Components/Loader/Loader'
import {ReactComponent as Arrow} from '../../media/Arrow.svg'

const CategoryNominees = ({items}) => (
	<motion.div
		initial={{height: 0}}
		animate={{height: 'auto'}}
		exit={{height: 0}}
		className="content"
	>
		{
			items.map(item => (
				<div className="nominee" key={item.id}>
					<div className="image">
						{
							item.image &&
							<img src={item.image.sizes.large} alt={item.title} />
						}
					</div>
					{ item.production_company && <p className="production_company" dangerouslySetInnerHTML={{__html: item.production_company}} /> }
					{ item.title && <p className="title" dangerouslySetInnerHTML={{__html: item.title}} /> }
				</div>
			))
		}
	</motion.div>
)

const Category = ({id, name, layoutRow, row, items, loading, expanded, fetched}) => {

	const dispatch = useDispatch()

	const toggle = () => {
		if(!fetched) {
			dispatch(getNominees({id, layoutRow, row}))
		}
		dispatch(toggleCategory({id, layoutRow, row}));
	}

	return(
		<div className={expanded ? 'NomineesListCategory active' : 'NomineesListCategory'}>
			<button onClick={toggle}>
				<span>{name}</span>
				{ loading && <Loader /> }
				<Arrow />
			</button>
			<AnimatePresence initial={false}>
				{
					!loading && expanded &&
					<CategoryNominees items={items} />
				}
			</AnimatePresence>
		</div>
	)
}

const NomineesList = ({row, categories}) => {
	return(
		<div className="NomineesList">
			{ categories.map((item, i) => <Category key={`NomineesList_${row}_${i}`} layoutRow={row} row={i} {...item} />) }
		</div>
	)
}

export default NomineesList
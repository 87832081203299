import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {ReactComponent as Arrow} from '../../media/Arrow.svg'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loader/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { getAddresses } from './addressesSlice'
import './Addresses.scss'


export const Address = ({row, title, name, image, slug}) => {
	return(
		<div className="Address">
			<Link to={`/hilsener/${slug}`}>
				{ image && <img src={image.sizes.medium} alt={name} /> }
				{ title && <p className="title" dangerouslySetInnerHTML={{__html: title}} /> }
				{ name && <p className="name" dangerouslySetInnerHTML={{__html: name}} /> }
				<Arrow />
			</Link>
		</div>
	)
}

const Addresses = () => {

	const dispatch = useDispatch()
	const {fetched, loading, content} = useSelector(state => state.addresses)

	useEffect(() => {
		if(!fetched) {
			dispatch(getAddresses())
		}
	}, [fetched, dispatch])

	return(
		<div className="Addresses">
			{
				loading
				? <Loader />
				:
				<>
					{ content.title && <h1 className="heading" dangerouslySetInnerHTML={{__html: content.title }} /> }
					{ content.layout && <Layout layout={content.layout} /> }
				</>
			}
		</div>
	)
}

export default Addresses
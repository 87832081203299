import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from './store'
import {Provider} from 'react-redux'

import App from './Containers/App/App';
import Home from './Containers/Home/Home'
import Nominees from './Containers/Nominees/Nominees'
import Jury from './Containers/Jury/Jury';
import Addresses from './Containers/Addresses/Addresses';
import Address from './Containers/Address/Address'
import Committee from './Containers/Committee/Committee';
import './theme.css'


const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '',
				element: <Home />
			},
			{
				path: 'nominerte',
				element: <Nominees />
			},
			{
				path: '/jury',
				element: <Jury />
			},
			{
				path: '/hilsener',
				element: <Addresses />
			},
			{
				path: '/hilsener/:id',
				element: <Address />
			},
			{
				path: '/komiteen',
				element: <Committee />
			}
		]
	}
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router} />
		</Provider>
	</React.StrictMode>
);
import React, {useEffect} from 'react'
import { getCommittee } from './committeeSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Components/Loader/Loader'
import './Committee.scss'

const People = ({people}) => {
	return(
		<div className="CommitteePeople">
			{
				people && people.map((person, i) =>
					<div key={`Committee_Person_${i}`} className="CommitteePerson">
						<div className="image">
							{ person.image && <img src={person.image.sizes.large} alt={person.name} /> }
						</div>
						{ person.title && <p className="title" dangerouslySetInnerHTML={{__html: person.title}} /> }
						{ person.name && <p className="name" dangerouslySetInnerHTML={{__html: person.name}} /> }
					</div>
				)
			}
		</div>
	)
}

const Committee = () => {
	const dispatch = useDispatch();
	const {fetched, content, loading} = useSelector(state => state.committee)

	const {title, people} = content;

	useEffect(() => {
		if(!fetched) { dispatch(getCommittee()) }
	}, [dispatch, fetched])

	return(
		<div className="Committee">
			{
				loading
				? <Loader />
				:
				<>
					{ title && <h1 className="heading" dangerouslySetInnerHTML={{__html: title}} /> }
					{ people && <People people={people} /> }
				</>
			}
		</div>
	)
}

export default Committee
import React, {useEffect} from 'react'
import Loader from '../../Components/Loader/Loader'
import Layout from '../../Components/Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from './nomineesSlice'
import './Nominees.scss'

const Nominees = () => {

	const dispatch = useDispatch();
	const {fetched, content, loading} = useSelector(state => state.nominees)

	const {title, layout} = content;

	useEffect(() => {
		if(!fetched) { dispatch(getCategories()) }
	}, [dispatch, fetched])

	return(
		<div className="Nominees">
			{
				loading
				? <Loader />
				:
				<>
					{ title && <h1 className="heading" dangerouslySetInnerHTML={{__html: title}} /> }
					{ layout && <Layout layout={layout} section="nominees" /> }
					<p className="fagpris"><a href="https://gullruten.no/fagprisen/nominerte/" target="_blank" rel="noreferrer">Se vinnere av årets Fagpris</a></p>
				</>
			}
		</div>
	)
}

export default Nominees
import React, {useEffect} from 'react'
import Navigation from '../../Components/Navigation/Navigation'
import { Outlet, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProgram } from './appSlice'
import Loader from '../../Components/Loader/Loader'
import Footer from '../../Components/Footer/Footer'
import BackToTop from '../../Components/BackToTop/BackToTop'
import './App.scss'

const App = () => {

	const location = useLocation();
	const dispatch = useDispatch();
	const {fetched, loading} = useSelector(state => state.app)


	useEffect(() => {
		if(!fetched) { dispatch(getProgram()) }
	}, [dispatch, fetched])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location])

	return(
		<div className="App">
			{
				location.pathname !== '/' && <Navigation />
			}
			{
				loading
				? <Loader />
				:
				<>
					<Outlet />
					<Footer />
				</>
			}
			<BackToTop />
		</div>
	)
}

export default App
import React from 'react'

import Ad from '../Ad/Ad'
import {Address} from '../../Containers/Addresses/Addresses'
import NomineesList from '../../Containers/Nominees/List'
import JuryList from '../../Containers/Jury/List'

export default function Layout({section, layout}) {
	return(
		<div className="Layout">
			{
				layout && layout.map((block, i) => {
					if(block.acf_fc_layout === 'categories') {
						if(section === 'nominees') {
							return <NomineesList row={i} key={`${block.acf_fc_layout}_${i}`} {...block} />
						}
						if(section === 'jury') {
							return <JuryList row={i} key={`${block.acf_fc_layout}_${i}`} {...block} />
						}
					}
					if(block.acf_fc_layout === 'annonse') {
						return <Ad key={`${block.acf_fc_layout}_${i}`} {...block} />
					}
					if(block.acf_fc_layout === 'address') {
						return <Address row={i} key={`${block.acf_fc_layout}_${i}`} {...block} />
					}
					return null
				})
			}
		</div>
	)
}
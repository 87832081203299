import React from 'react'
import './Home.scss'
import Navigation from '../../Components/Navigation/Navigation'
import { useSelector } from 'react-redux'
import Logos from '../../Components/Logos/Logos'
import { ReactComponent as Logo } from '../../media/gullruten-logo.svg'

const Intro = ({image, title}) => {
	return(
		<div className="HomeIntro">
			<Logo />
			<div className="image">
				{ title && <h1 dangerouslySetInnerHTML={{__html: title}} /> }
				{ image && <img src={image.sizes.large} alt="Gullruten program" /> }
			</div>
		</div>
	)
}

const Home = () => {

	const {content} = useSelector(state => state.app)

	const { partners, title, image } = content;

	return(
		<div className="Home">
			<Intro image={image} title={title} />
			<Navigation />
			<Logos content={partners} />
		</div>
	)
}

export default Home
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {baseUrl} from '../../Components/baseURL';

const getCategories = createAsyncThunk('nominees/getCategories', async () => {
	return fetch(`${baseUrl()}/get_categories?section=nominees`)
		.then(response => response.json())
})

const getNominees = createAsyncThunk('nominees/getNominees', async ({id}) => {
	return fetch(`${baseUrl()}/get_nominees?category=${id}`)
		.then(response => response.json())
})

const initialState = {
	loading: false,
	fetched: false,
	content: {}
}

export const nomineesSlice = createSlice({
	name: 'nominees',
	initialState,
	reducers: {
		toggleCategory: (state, action) => {
			const {row, layoutRow} = action.payload;
			state.content.layout[layoutRow].categories[row].expanded = !state.content.layout[layoutRow].categories[row].expanded;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCategories.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getCategories.fulfilled, (state, {payload}) => {
			state.loading = false
			state.fetched = true
			state.content = payload
		})
		builder.addCase(getNominees.pending, (state, action) => {
			const {layoutRow, row} = action.meta.arg;
			const selected = state.content.layout[layoutRow].categories[row];
			selected.loading = true;

		})
		builder.addCase(getNominees.fulfilled, (state, action) => {
			const {layoutRow, row} = action.meta.arg;
			const selected = state.content.layout[layoutRow].categories[row];
			selected.loading = false;
			selected.fetched = true
			selected.items = action.payload
		});
	}
})
export const { toggleCategory } = nomineesSlice.actions
export { getCategories, getNominees }
export default nomineesSlice.reducer
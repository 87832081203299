import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./Containers/App/appSlice";
import nomineesReducer from "./Containers/Nominees/nomineesSlice"
import juryReducer from "./Containers/Jury/jurySlice"
import addressesReducer from './Containers/Addresses/addressesSlice'
import committeeReducer from './Containers/Committee/committeeSlice'

export default configureStore({
	reducer: {
		app: appReducer,
		nominees: nomineesReducer,
		jury: juryReducer,
		addresses: addressesReducer,
		committee: committeeReducer
	}
})
import React from 'react'
import './Logos.scss'

const Logos = ({content}) => {
	const {title, logos} = content;
	return(
		<div className="Logos">
			{ title && <h2 dangerouslySetInnerHTML={{__html: title}} /> }
			<ul>
				{
					logos.map((item) =>
					<li key={item.logo.id}>
						{
							item.url
							? <a href={item.url} target="_blank" rel="noreferrer"><img src={item.logo.sizes.medium} alt="" /></a>
							: <img src={item.logo.sizes.medium} alt="" />
						}

					</li>)
				}
			</ul>
		</div>
	)
}

export default Logos
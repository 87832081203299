import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {baseUrl} from '../../Components/baseURL';

const getCategories = createAsyncThunk('jury/getCategories', async () => {
	return fetch(`${baseUrl()}/get_categories?section=jury`)
		.then(response => response.json())
})

const getJury = createAsyncThunk('jury/getJury', async ({id}) => {
	return fetch(`${baseUrl()}/get_jury?category=${id}`)
		.then(response => response.json())
})

const initialState = {
	loading: false,
	fetched: false,
	content: {}
}

export const jurySlice = createSlice({
	name: 'jury',
	initialState,
	reducers: {
		toggleCategory: (state, action) => {
			const {row, layoutRow} = action.payload;
			state.content.layout[layoutRow].categories[row].expanded = !state.content.layout[layoutRow].categories[row].expanded;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCategories.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getCategories.fulfilled, (state, {payload}) => {
			state.loading = false
			state.fetched = true
			state.content = payload
		})
		builder.addCase(getJury.pending, (state, action) => {
			const {layoutRow, row} = action.meta.arg;
			const selected = state.content.layout[layoutRow].categories[row];
			selected.loading = true;

		})
		builder.addCase(getJury.fulfilled, (state, action) => {
			const {layoutRow, row} = action.meta.arg;
			const selected = state.content.layout[layoutRow].categories[row];
			selected.loading = false;
			selected.fetched = true
			selected.items = action.payload
		});
	}
})
export const { toggleCategory } = jurySlice.actions
export { getCategories, getJury }
export default jurySlice.reducer
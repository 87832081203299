import React from 'react'
import { useSelector } from 'react-redux'
import Logos from '../../Components/Logos/Logos'
import './Footer.scss'

const Footer = () => {

	const {content} = useSelector(state => state.app)
	const { sponsors } = content;

	return(
		<footer className="Footer">
			<Logos content={sponsors} />
			<p className="fagprisen">Har du sjekket <a target="_blank" rel="noreferrer" href="https://gullruten.no/fagprisen/">Fagprisen vår?</a></p>
			<p className="copy">Design og utvikling av <a target="_blank" rel="noreferrer" href="//bigfish.no">Big Fish</a></p>
		</footer>
	)
}

export default Footer
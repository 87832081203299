import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {baseUrl} from '../../Components/baseURL';

const getAddresses = createAsyncThunk('addresses/getAddresses', async () => {
	return fetch(`${baseUrl()}/get_addresses`)
		.then(response => response.json())
})

const initialState = {
	loading: false,
	fetched: false,
	content: {},
	currentAddress: {}
}

export const addressesSlice = createSlice({
	name: 'addresses',
	initialState,
	reducers: {
		selectAddress: (state, payload) => {
			console.log(payload)
			state.currentAddress = state.content.layout.filter(block => block.slug = payload)
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getAddresses.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getAddresses.fulfilled, (state, {payload}) => {
			state.loading = false
			state.fetched = true
			state.content = payload
		})
	}
})

export { getAddresses }
export const { selectAddress } = addressesSlice.actions
export default addressesSlice.reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {baseUrl} from '../../Components/baseURL';

const getCommittee = createAsyncThunk('addresses/getCommittee', async () => {
	return fetch(`${baseUrl()}/get_committee`)
		.then(response => response.json())
})

const initialState = {
	loading: false,
	fetched: false,
	content: {},
}

export const committeeSlice = createSlice({
	name: 'addresses',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(getCommittee.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getCommittee.fulfilled, (state, {payload}) => {
			state.loading = false
			state.fetched = true
			state.content = payload
		})
	}
})

export { getCommittee }
export default committeeSlice.reducer
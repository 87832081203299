import React, {useState} from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import {ReactComponent as Arrow} from '../../media/Arrow.svg'

import './BackToTop.scss'

const BackToTop = () => {

	const [active, setActive] = useState(false)

	const goBackToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		})
	}

	useScrollPosition(({ prevPos, currPos }) => {
		if(currPos.y < -500) setActive(true)
		if(currPos.y > -200) setActive(false)
	 }, [active])

	if(active) {
		return(
			<button onClick={goBackToTop} className="BackToTop">
				<Arrow />
			</button>
		)
	}
	return null
}

export default BackToTop
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {baseUrl} from '../../Components/baseURL';

const getProgram = createAsyncThunk('app/getProgram', async () => {
	return fetch(`${baseUrl()}/get_program`)
		.then(response => response.json())
})

const initialState = {
	loading: false,
	fetched: false,
	content: {
		image: false,
		title: '',
		partners: {
			title: '',
			logos: []
		},
		sponsors: {
			title: '',
			logos: []
		}
	}
}

export const appSlice = createSlice({
	name: 'app',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(getProgram.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getProgram.fulfilled, (state, {payload}) => {
			state.loading = false
			state.fetched = true
			state.content = payload.home
		})
	}
})

export { getProgram }
export default appSlice.reducer



